import React from "react";
import config from "../../config/config";
import { useSelector } from "react-redux";

function MenuItems({ menuDishes, placeOrder, onDetailOpen }) {
  const cartData = useSelector((state) => state.cart);
  const cartItemData = cartData.filter(
    (item) => menuDishes.item_no === item.id
  );

  return (
    <>
      {/* <div id='menuitem' class="menuitem p-2 mb-2 rounded" style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)' }}> */}
      <div
        id="menuitem"
        class="menuitem p-2 mb-2 rounded d-flex flex-column"
        style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)" }}
      >
        <div id={menuDishes.category_code} style={{ color: "#f0f8ff00" }}>
          {menuDishes.category_code}
        </div>
        <img
          src={`https://ik.imagekit.io/agjl6gve9/hepn/${process.env.REACT_APP_MODE}/${process.env.REACT_APP_MODE}_${menuDishes.item_no}.jpg`}
          className="carousel-cls"
          alt=""
          onClick={() => onDetailOpen(menuDishes)}
        />
        {menuDishes.is_top_pick === true ? (
          <div className="position-absolute d-flex best-seller-tag ms-2">
            <span className="best-seller-text text-white">Top Pick!</span>
          </div>
        ) : (
          ""
        )}

        <div className="d-grid text-start mb-4">
          <span className="menu-title mt-3 text-break text-uppercase">
            {menuDishes.item_desc}
            {menuDishes.bal_qty > 0 ? (
              <span className="bal-qty-text mt-2">
                ({menuDishes.bal_qty - (cartItemData[0]?.qty || 0)} left)
              </span>
            ) : (
              ""
            )}
          </span>
          <span className="menu-price-text mt-2">
            S$
            {Number(
              menuDishes?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
            ).toFixed(2)}
          </span>
        </div>
        {menuDishes.is_soldout === "Y" ||
        !menuDishes.is_soldout ||
        menuDishes.is_emenu_disable === "Y" ||
        !menuDishes.is_emenu_disable ||
        (menuDishes.is_avl_limit_check === "Y" && menuDishes.bal_qty === 0) ||
        !menuDishes.is_avl_limit_check ? (
          <button className="sold-out-btn mb-2 mt-4" disabled>
            SOLD OUT
          </button>
        ) : (
          <>
            {cartItemData &&
            cartItemData.length > 0 &&
            cartItemData[0].qty >= menuDishes.bal_qty &&
            menuDishes.bal_qty !== 0 ? (
              <button className="sold-out-btn mb-2 mt-auto flex-end" disabled>
                ADD
              </button>
            ) : (
              <button
                className="border-btn add-cart-btn d-flex justify-content-center align-items-center shaking-button mb-2 mt-auto flex-end"
                onClick={() => placeOrder(menuDishes)}
              >
                ADD
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default MenuItems;
