import Header from "../../component/header";
import { useEffect } from "react";


function PrivacyPolicy() {
    // hide div with class header-status
    useEffect(() => {
      document.querySelector(".header-status").style.setProperty("display", "none", "important");
    }, []);
  
  return (
    <div className="App">

    <div className="qr-before-body w-100">
    <Header title="Privacy" headerStatus="false"  />

    <div className="inner-div main-div position-absolute w-100">
      <div className="w-100">
        <div className="d-flex ps-4 pb-2 pe-3 pt-3 bg-white">
      <div className="container font-12 text-start">
      <p className="text-start">This Privacy Policy outlines the procedure for the acquisition, utilization, and dissemination of personal data by Solution Details Pte. Ltd and its associated companies (collectively referred to as “Solution Details”) when using the services, applications, platforms, or solutions (collectively referred to as “Services”) offered by Solution Details. The policy describes the purpose for which such personal data is used and the methods by which such data is handled. Solution Details understands the significance of safeguarding the personal data collected from users of the Services and takes appropriate measures to ensure the confidentiality, integrity, and security of any personal data gathered in accordance with this Privacy Policy.</p>

 

      <p className="text-start">This Privacy Policy informs you of your rights and choices regarding the collection, use, and disclosure of your personal data by Solution Details Pte. Ltd and its related companies (collectively, “Solution Details”) when you use our solutions, platforms, applications, or services (collectively “Services”). By accepting our terms of use upon signing up to our platform, you are entering into a contract with us under which you can use the platform, in accordance with our terms of use. While we require some information from you to perform our obligations under this contract, you have the choice to share only the information that you are comfortable with.</p>


      <p className="text-start">To control and manage the amount of personal data you share with us, you can take the following steps:</p>

      <p className="text-start">
1. Direct Marketing:

 

If you do not wish to receive newsletters from us, you can unsubscribe at any time, and we will refrain from sending you any promotional offers.


Withdrawal of Consent: You have the right to withdraw your consent for the processing of your personal data for specific purposes, such as marketing. You may do so by submitting a request via email to lorne.chew@solutiondetails.com.sg.1.

 

Your Rights as an Individual

(a) Right to access

You have the right to know which personal data we collect, use, and process about you. We will respond to your request to access your personal data within thirty (30) days upon receiving your request. If we are unable to comply with your request, we will notify you of the reasons and any additional time required.


(b) Right to rectification

If you believe that the personal data that we hold about you is inaccurate or incomplete, you have the right to request for rectification. We will respond to your request within thirty (30) days upon receiving your request. If we are unable to comply with your request, we will notify you of the reasons and any additional time required.


(c) Right to withdraw your consent to the processing of your personal data

You may withdraw your consent to our collection, use, and disclosure of your personal data for any or all purposes. Upon receiving your request, we may require a reasonable amount of time to process your request and to notify you of the consequences of such withdrawal, including any legal implications. Depending on the nature and scope of your request, we may not be able to continue providing our services to you, and we will notify you before completing the processing of your request.


We will endeavour to process your request within ten (10) business days upon receipt and will notify you of any additional time required.


To exercise your rights, you may send in your request via email to lorne.chew@solutiondetails.com.sg at any time. We may charge you a reasonable fee for the handling and processing of your requests to access your personal data and will inform you of the amount charged in advance. We will process your request in accordance with this Privacy Policy and our obligations under applicable laws.

</p>
<p className="text-start">
2. Data sharing

 

In order to ensure the efficient operation of our business, we engage selected service providers to assist us and provide them with limited access to some of our data in accordance with the Purposes. Prior to sharing any personal data with these partners for processing on our behalf, we conduct an audit of each individual company to ensure they meet legal data protection requirements and commit to protecting your personal data to a comparable standard as required under the PDPA and other relevant data protection laws. Rest assured that we do not disclose your data to unauthorized third parties.

 

a. Service Providers and Data Processors

To conduct our business efficiently, we engage different service providers and data processors to assist us in our daily processing activities. These service providers and data processors are authorized to access and process your personal data only in accordance with our instructions and the applicable local data protection laws and requirements. We monitor our processors and only include those who comply with our data protection standards. Our user platforms and databases run on cloud resources provided by the EU subsidiaries of Microsoft Azure, Google, Oracle Cloud Infrastructure (OCI), and Amazon Web Services (AWS). As we may use different data processors and change them from time to time, we are unable to identify all individual recipients of personal data in this privacy policy. However, upon request, we will disclose the name of the processor(s) in use at that time.


b. Third Parties in Partnership or Contractual Relationship with Tabsquare

We have partnered with and entered into contracts with third parties to achieve the purposes outlined in this Privacy Policy. In some cases, personal information obtained through the Platform may be shared with such third parties. For instance, we may share your personal information with Solution Details’ partner, IMDA & qlub. We ensure that any third parties we work with are bound by contractual obligations to protect your personal data and comply with applicable data protection laws.

 

We may also share your personal data with law enforcement agencies, government, and regulatory bodies to meet applicable legal or regulatory obligations.

 </p>

 <p className="text-start">
3. Personal data of other individuals

In the event that you furnish us with personal data pertaining to other individuals by utilizing our platform (for instance, providing us with your friend's name, contact information, and residential address when placing an order on the QR link), you hereby represent and warrant that you are authorized by such individuals to disclose their personal data to us and that these individuals have given their explicit and informed consent for their personal data to be collected, used, and disclosed by us for the stated Purposes.

 </p>
 <p className="text-start">

4. Right of modification

In order to ensure compliance with applicable legal and statutory requirements, including the PDPA, we reserve the right to modify this privacy notice. We will notify you of any significant changes, such as changes to the purposes for which personal data is processed, or the addition of new processing activities.</p>
      </div>
    </div>
    </div>
  </div>
  </div>
  </div>
  );
}

export default PrivacyPolicy;