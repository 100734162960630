import moment from "moment";
import api from "../api/api";
import * as url from "../api/url";
import { STORAGEKEY } from "../constants";

export const userSessionId = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParams(url.GET_SESSION_ID, {
        apikey: process.env.REACT_APP_API_KEY,
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const generateQRCode = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithUrlEncoded(
        url.GENERATE_QR_CODE +
          `?sessionid=${localStorage.getItem(STORAGEKEY.SESSION_ID)}`,
        data
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const QRCodeValidation = (qrCode, tableNo) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParams(url.QR_CODE_VALIDATION, {
        sessionid: localStorage.getItem(STORAGEKEY.SESSION_ID)
          ? localStorage.getItem(STORAGEKEY.SESSION_ID)
          : "",
        storename: localStorage.getItem(STORAGEKEY.STORE_NAME)
          ? localStorage.getItem(STORAGEKEY.STORE_NAME)
          : "",
        tableno: tableNo,
        qrcode: qrCode,
        // get current date in this format : 2023/04/13 03:40:00
        // trandate: "2021/01/27",
        trandate: moment().format("YYYY/MM/DD"),
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const storeMenuItems = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParams(url.GET_HOME_CATEGORY, {
        sessionid: localStorage.getItem(STORAGEKEY.SESSION_ID)
          ? localStorage.getItem(STORAGEKEY.SESSION_ID)
          : "",
        storename: localStorage.getItem(STORAGEKEY.STORE_NAME)
          ? localStorage.getItem(STORAGEKEY.STORE_NAME)
          : "",
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const storeStockList = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParams(url.GET_STORE_STOCK_LIST, {
        sessionid: localStorage.getItem(STORAGEKEY.SESSION_ID)
          ? localStorage.getItem(STORAGEKEY.SESSION_ID)
          : "",
        storename: localStorage.getItem(STORAGEKEY.STORE_NAME)
          ? localStorage.getItem(STORAGEKEY.STORE_NAME)
          : "",
        dayname: moment().format("dddd"),
        logindate: moment().format("YYYY-MM-DD"), // format 2023-04-13
        apikey: process.env.REACT_APP_API_KEY,

      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const storeMenuButton = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParams(url.GET_MENU_BUTTON_LIST, {
        sessionid: localStorage.getItem(STORAGEKEY.SESSION_ID)
          ? localStorage.getItem(STORAGEKEY.SESSION_ID)
          : "",
        storename: localStorage.getItem(STORAGEKEY.STORE_NAME)
          ? localStorage.getItem(STORAGEKEY.STORE_NAME)
          : "",
        dayname: moment().format("dddd"),
        logindate: moment().format("YYYY-MM-DD"),
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getGSTValue = (taxname) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParams(url.GET_GST, {
        apikey: process.env.REACT_APP_API_KEY,
        sessionid: localStorage.getItem(STORAGEKEY.SESSION_ID)
          ? localStorage.getItem(STORAGEKEY.SESSION_ID)
          : "",
        taxname: taxname,
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const PostSaleCreate = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithUrlEncoded(
        url.POST_ORDER +
          `?sessionid=${localStorage.getItem(STORAGEKEY.SESSION_ID)}`,
        data
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetServiceCharges = (serviceName) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithParams(url.GET_SERVICE_CHARGE, {
        apikey: process.env.REACT_APP_API_KEY,
        sessionid: localStorage.getItem(STORAGEKEY.SESSION_ID)
          ? localStorage.getItem(STORAGEKEY.SESSION_ID)
          : "",
        servicename: '%'
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        console.log("err", err);
        reject(err.response);
      });
  });
}
