import React  from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../component/header";
import config from "../../config/config";
function Payment() {
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Payments" />
          <div className="inner-div payment-div position-absolute w-100">
            <div className="mt-4 me-4 ms-4 bg-white">
              <div className="d-flex pt-4 ps-4">
                <label className="payment-data">
                  1x
                </label>
                <label className="ms-3 w-50 payment-data">
                  Pavilion Fish Head Curry
                </label>
                <label className="position-absolute end-0 me-5 payment-data">
                  S$33.99
                </label>
              </div>
              <hr className="pb-3 ms-4 me-4 border-hr" />
              <div className="d-flex justify-content-between me-4 ms-4 pb-2">
                <div className="d-grid text-start">
                  <label className="payment-service-text mb-2 pb-2">
                    Service Charge
                  </label>
                  <label className="payment-service-text mb-2 pb-2">
                    GST 8%
                  </label>
                  <label className="payment-bill-text mb-2 pb-2">
                    Your Bill
                  </label>
                </div>
                <div className="text-end d-grid">
                  <label className="payment-service-text mb-2 pb-2">
                    S$2.69
                  </label>
                  <label className="payment-service-text mb-2 pb-2">
                    S$2.15
                  </label>
                  <label className="payment-bill-text mb-2 pb-2">
                    S$31.81
                  </label>
                </div>
              </div>
            </div>
            <Card className="me-4 ms-4 mt-4">
            <label className="payment-paying-text ms-4 mt-4 text-start">
              You are paying: S$31.81
            </label>
            <Link
              to="/payment-success"
              className="apple-btn text-white text-center me-4 ms-4 mb-2 mt-3"
            >
              <img src={`/assets/commonImages/${config.apple}.png`} alt="" />
              <span className="mt-5 ms-3">Pay</span>
            </Link>
            <div className="d-flex justify-content-between ps-1 pe-1 me-4 ms-4 mt-3 mb-2">
              <img src={`/assets/commonImages/${config.payment}.png`} alt="payment images" />
            </div>
            <Link
              to="/payment-success"
              className="pay-btn text-center text-white me-4 ms-4 mb-2 mt-3"
            >
              Pay Now
            </Link>
          </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
