import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../component/header";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, updateToCart } from "../../redux/cartSlice";
import { BsDashLg, BsPlusLg } from "react-icons/bs";
import { useMemo } from "react";

function CustomizeDish() {
  const { state } = useLocation();
  const [totalPrice, setTotalPrice] = useState(0);
  const dineOption = localStorage.getItem("selectiDineOption");
  const quantity = useRef(state.qty ? state.qty : 1);
  const [counter, setCounter] = useState(state.qty ? state.qty : 1);
  const [numChecked, setNumChecked] = useState(0);
  const navigate = useNavigate();
  const [formState, setFormState] = useState(true);
  let initialValue = {};
  let validation = {};

  const getGroupAddOns = (addOns, field) =>
    addOns?.reduce(function (a, e) {
      // GROUP BY estimated key (estKey), well, may be a just plain key
      // a -- Accumulator result object
      // e -- sequentally checked Element, the Element that is tested just at this itaration

      // new grouping name may be calculated, but must be based on real value of real field
      let estKey = e[field];

      (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
      return a;
    }, {});

  // TODO: move this code below into useEffect hook
  for (let value of state.item?.itemmaster_menutype_grpdtls) {
    const modifierItems = getGroupAddOns(state?.addOnValues, "modifier_name")
      ? getGroupAddOns(state?.addOnValues, "modifier_name")
          [value?.modifier_name]?.map((it) =>
            Array.from(Array(it?.modifier_qty), (_, x) => x)
              .flat(Infinity)
              .map((o) => it?.citem_name)
          )
          .flat(Infinity)
      : [];

    if (value.max_qty === 1) {
      initialValue = {
        ...initialValue,
        [value.modifier_name]:
          state.addOnValues && state.addOnValues.length > 0
            ? state.addOnValues.filter(
                (addOn) => addOn.modifier_name === value.modifier_name
              )[0].citem_name
            : state.item?.itemmaster_menutypedtls.filter(
                (customItems) =>
                  customItems.modifier_name === value.modifier_name
              )[0].citem_name,
      };
      validation = {
        ...validation,
        [value.modifier_name]: Yup.string().required(
          `${value.modifier_name} is required`
        ),
      };
    } else {
      const filterValues = state.item?.itemmaster_menutypedtls.filter(
        (customItems) =>
          customItems.modifier_name === value.modifier_name &&
          customItems.max_qty > 0
      );
      let multipleItemsDefault = [];
      if (state.addOnValues) {
        multipleItemsDefault =
          state.addOnValues &&
          state.addOnValues.length > 0 &&
          state.addOnValues
            .filter((addOn) => addOn.modifier_name === value.modifier_name)
            .map((item) => item.citem_name);
      } else {
        if (filterValues.length === 1) {
          multipleItemsDefault.push(filterValues[0].citem_name);
        }
        if (filterValues.length > 1) {
          multipleItemsDefault.push(filterValues[0].citem_name);
          multipleItemsDefault.push(filterValues[1].citem_name);
        }
      }
      initialValue = {
        ...initialValue,
        [value.modifier_name]:
          value.modifier_name === "MAIN" ? "MAIN" : modifierItems,
      };
      validation = {
        ...validation,
        // [value.modifier_name]: Yup.array()
        //   .max(
        //     value.max_qty,
        //     `Please select only ${value.max_qty} ${value.modifier_name}`
        //   )
        //   .min(
        //     value.max_qty,
        //     `Please select ${value.max_qty} ${value.modifier_name}`
        //   ),
        [value.modifier_name]: Yup.array().test(
          "maxQtyTest",
          `Please select ${value.max_qty} ${value.modifier_name}`,
          (valueItem) => validateModifier(value, valueItem)
        ),
      };
    }
  }

  const validateModifier = (value, valueItem) => {
    const maxAvlQty = state.item?.itemmaster_menutype_grpdtls.filter(
      (item) => value.modifier_name === item.modifier_name
    )[0].max_qty;

    const groupLimit = state.item?.itemmaster_menutype_grpdtls.filter(
      (item) => value.modifier_name === item.modifier_name
    )[0].group_limit;

    let selectedQty = 0;
    valueItem?.forEach((item) => {
      selectedQty += state.item?.itemmaster_menutypedtls.filter(
        (modItem) => modItem.citem_name === item
      )[0]?.qty;
    });

    if (maxAvlQty === 0 && groupLimit === 0) {
      return true;
    }

    return maxAvlQty <= selectedQty;
  };

  const validModifierCheck = (value, valueItem) => {
    const maxAvlQty = state.item?.itemmaster_menutype_grpdtls.filter(
      (item) => value.modifier_name === item.modifier_name
    )[0].max_qty;

    const groupLimit = state.item?.itemmaster_menutype_grpdtls.filter(
      (item) => value.modifier_name === item.modifier_name
    )[0].group_limit;

    let selectedQty = 0;
    valueItem?.forEach((item) => {
      selectedQty += state.item?.itemmaster_menutypedtls.filter(
        (modItem) => modItem.citem_name === item
      )[0]?.qty;
    });

    if (maxAvlQty === 0 && groupLimit === 0) {
      return false;
    }

    return maxAvlQty <= selectedQty;
  };

  const dispatcher = useDispatch();
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: Yup.object(validation),
    onSubmit: (values) => {
      let addOnValues = [];
      if (Object.keys(values) && Object.keys(values).length > 0) {
        for (const addOn of Object.keys(values)) {
          state.item?.itemmaster_menutypedtls
            .filter(
              (item) =>
                item.modifier_name === addOn &&
                values[addOn]?.includes(item.citem_name)
            )
            .map((addOnData) => {
              const modifier_qty =
                typeof values[addOnData?.modifier_name] === "string"
                  ? 1
                  : values[addOnData?.modifier_name].filter(
                      (it) => it === addOnData.citem_name
                    ).length;

              addOnValues.push({
                modifier_qty,
                item_no: addOnData.citem_no,
                modifier_name: addOnData.modifier_name,
                citem_name: addOnData.citem_name,
                price_dtls:
                  dineOption === "dinein"
                    ? addOnData.price_dtls[0].dine_in_price
                    : addOnData.price_dtls[0].takeaway_price,
                sku_no: addOnData.citem_no.replace("PRD", "SKU"),
                uom: addOnData.uom,
              });
            });
        }
      }
      let price = 0;
      if (totalPrice) {
        price = totalPrice;
      } else {
        price =
          dineOption === "dinein"
            ? state.item?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
            : state.item?.selling_uom_dtls[0]?.price_dtls[0]?.takeaway_price;
      }
      if (state.qty) {
        dispatcher(
          updateToCart({
            uuid: state.uuid,
            id: state.item.item_no,
            addOnValues: addOnValues,
            item: state.item,
            qty: quantity.current,
            price,
          })
        );
      } else {
        dispatcher(
          addToCart({
            id: state.item.item_no,
            addOnValues: addOnValues,
            item: state.item,
            qty: quantity.current,
            price,
          })
        );
      }

      navigate("/home", {
        state: {
          addOnValues: addOnValues,
          item: state.item,
          qty: quantity.current,
          price:
            (state.item?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price +
              totalPrice) *
            quantity.current,
        },
      });
    },
  });

  const incrementCounter = (modifierName, citemName) => {
    formik.setValues({
      ...formik.values,
      [modifierName]: [...formik.values[modifierName], citemName],
    });
  };

  const decrementCounter = (modifierName, citemName) => {
    const allElExceptTarget = formik.values[modifierName].filter(
      (it) => it !== citemName
    );

    const targetEl = formik.values[modifierName].filter(
      (it) => it === citemName
    );

    if (targetEl.length > 1) {
      targetEl.shift();
    }

    formik.setValues({
      ...formik.values,
      [modifierName]: [...allElExceptTarget, ...targetEl],
    });
  };

  useEffect(() => {
    let itemExist = [];
    Object.keys(formik.values).map((selectedItems) => {
      if (typeof formik.values[selectedItems] === "string") {
        itemExist.push(
          state.item?.itemmaster_menutypedtls.filter(
            (it) =>
              it.modifier_name === selectedItems &&
              it.citem_name === formik.values[selectedItems]
          )
        );
      } else if (typeof formik.values[selectedItems] === "object") {
        formik.values[selectedItems].map((it) => {
          itemExist.push(
            state.item?.itemmaster_menutypedtls.filter(
              (item) =>
                item.citem_name === it && item.modifier_name === selectedItems
            )
          );
        });
      }
    });

    if (itemExist.flat(Infinity) && itemExist.flat(Infinity).length > 0) {
      setTotalPrice(
        itemExist.flat(Infinity).reduce((total, item) => {
          return (
            total +
            item.price_dtls[0][
              dineOption === "dinein" ? "dine_in_price" : "takeaway_price"
            ] *
              item.qty
          );
        }, 0)
      );
    }
  }, [formik?.values]);

  const getQtyItem = (modifierName, citemName) =>
    formik.values[modifierName].filter((it) => it === citemName).length;

  const getGroupQty = (modifierName, exceptCitemName) =>
    Array.isArray(formik.values[modifierName])
      ? formik.values[modifierName].filter((it) => it !== exceptCitemName)
          .length
      : 1;

  const total = useMemo(() => totalPrice, [totalPrice]);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Customize your order" />
          <div
            className="inner-div main-div position-absolute w-100"
            style={{ maxHeight: "90vh" }}
          >
            <div className="pt-3 pe-3 ps-3 me-2 ms-2">
              <img
                src={`https://ik.imagekit.io/agjl6gve9/hepn/${process.env.REACT_APP_MODE}/${process.env.REACT_APP_MODE}_${state.item.item_no}.jpg`}
                alt=""
                className="customize-img"
              />
            </div>
            <Form onSubmit={formik.handleSubmit}>
              <div className="mt-3 d-flex selection-div1">
                <div className="set-width">
                  <div className="d-grid pt-3 ps-3">
                    <label className="text-start customize-item-name">
                      {state.item.item_name}
                    </label>
                    {dineOption === "takeaway" ? (
                      <label className="text-start customize-item-price">
                        S$
                        {Number(
                          state.item?.selling_uom_dtls[0]?.price_dtls[0]
                            ?.takeaway_price
                        ).toFixed(2)}
                      </label>
                    ) : (
                      <label className="text-start customize-item-price">
                        S$
                        {Number(
                          state.item?.selling_uom_dtls[0]?.price_dtls[0]
                            ?.dine_in_price
                        ).toFixed(2)}
                      </label>
                    )}
                  </div>

                  {state.item?.itemmaster_menutype_grpdtls &&
                    state.item?.itemmaster_menutype_grpdtls.length > 0 &&
                    state.item?.itemmaster_menutype_grpdtls
                      .sort(
                        (a, b) =>
                          a.item_menutype_grpdtls - b.item_menutype_grpdtls
                      )
                      .map((custom, i) => (
                        <>
                          {custom.max_qty === 1 ? (
                            <>
                              <div
                                className="d-flex selection1  ps-3 pt-4"
                                key={i}
                              >
                                <label className="customize-item-modi-name  text-uppercase">
                                  {custom.modifier_name} <span>*</span>
                                </label>
                                <label className="dot ms-3 me-3" />
                                <label className="customize-item-modi-select text-uppercase">
                                  Select 1
                                </label>
                              </div>

                              {state.item?.itemmaster_menutypedtls
                                .filter(
                                  (customItems) =>
                                    customItems.modifier_name ===
                                    custom.modifier_name
                                )
                                .map((customData, index) => (
                                  <Form.Group
                                    className="ps-3 pe-4 pb-3"
                                    key={index}
                                  >
                                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                                      <Form.Check
                                        type="radio"
                                        id={`${customData.modifier_name}`}
                                        name={`${customData.modifier_name}`}
                                        label={`${customData.citem_name}`}
                                        onChange={formik.handleChange}
                                        value={customData.citem_name}
                                        checked={
                                          formik.values[
                                            customData.modifier_name
                                          ] === `${customData.citem_name}`
                                            ? true
                                            : false
                                        }
                                        className="customize-item-citen-name text-uppercase"
                                      />
                                      {dineOption === "takeaway" ? (
                                        <span className="customize-item-citen-name position-absolute end-0 pe-4 text-uppercase">
                                          {customData?.price_dtls[0]
                                            ?.dine_in_price > 0
                                            ? `+$S${(customData?.price_dtls[0]?.takeaway_price).toFixed(
                                                2
                                              )}`
                                            : ""}
                                        </span>
                                      ) : (
                                        <span className="customize-item-citen-name position-absolute end-0 pe-4 text-uppercase">
                                          {customData?.price_dtls[0]
                                            ?.dine_in_price > 0
                                            ? `+$S${(customData?.price_dtls[0]?.dine_in_price).toFixed(
                                                2
                                              )}`
                                            : ""}
                                        </span>
                                      )}
                                    </div>
                                  </Form.Group>
                                ))}
                            </>
                          ) : (
                            <>
                              <div
                                className="d-flex selection1  ps-3 pt-4"
                                key={i}
                              >
                                <label className="customize-item-modi-name text-uppercase">
                                  {custom.modifier_name} <span>*</span>
                                </label>
                                <label className="dot ms-3 me-3" />

                                {custom.group_limit !== 0 && (
                                  <label className="customize-item-modi-select text-uppercase">
                                    Select {custom.max_qty}
                                  </label>
                                )}
                              </div>
                              <span className="font-14 d-flex ps-3 text-danger">
                                {formik &&
                                  formik.errors &&
                                  Object.keys(formik.errors).length > 0 &&
                                  formik.errors[custom.modifier_name]}
                              </span>

                              {state.item?.itemmaster_menutypedtls
                                .filter(
                                  (customItems) =>
                                    customItems.modifier_name ===
                                    custom.modifier_name
                                )
                                .map((customData, index) => (
                                  <Form.Group className="ps-3 pe-4 pb-3">
                                    <div className="pt-3 pb-2 d-flex justify-content-between div-border">
                                      <Form.Check
                                        type="checkbox"
                                        id={index}
                                        name={`${customData.modifier_name}`}
                                        label={`${customData.citem_name}  (x${customData.qty})`}
                                        onChange={(e) => {
                                          const price =
                                            dineOption === "takeaway"
                                              ? customData?.price_dtls[0]
                                                  ?.takeaway_price
                                              : customData?.price_dtls[0]
                                                  ?.dine_in_price;

                                          const groupLimit = custom.group_limit;
                                          const minQty = custom.max_qty; // it should be min_qty
                                          const isUnlimitedSelection =
                                            groupLimit === 0 && minQty === 0;

                                          if (e.target.checked) {
                                            if (
                                              !isUnlimitedSelection &&
                                              formik.values[
                                                customData.modifier_name
                                              ].length >= custom.max_qty
                                            ) {
                                              e.preventDefault();
                                              return;
                                            }

                                            setTotalPrice(totalPrice + price);

                                            setNumChecked(
                                              numChecked + customData.qty
                                            );
                                            formik.handleChange(e);
                                          } else {
                                            setNumChecked(
                                              numChecked - customData.qty
                                            );
                                            setTotalPrice(totalPrice - price);

                                            formik.setValues({
                                              ...formik.values,
                                              [customData.modifier_name]: [
                                                ...formik.values[
                                                  customData.modifier_name
                                                ].filter(
                                                  (it) =>
                                                    it !== customData.citem_name
                                                ),
                                              ],
                                            });
                                          }
                                        }}
                                        value={customData.citem_name}
                                        checked={formik.values[
                                          customData.modifier_name
                                        ]?.includes(customData.citem_name)}
                                        className="customize-item-citen-name text-start text-uppercase"
                                        disabled={
                                          (validModifierCheck(
                                            customData,
                                            formik.values[
                                              customData.modifier_name
                                            ]
                                          ) &&
                                            !formik.values[
                                              customData.modifier_name
                                            ]?.includes(
                                              customData.citem_name
                                            )) ||
                                          customData.is_soldout === "Y" ||
                                          customData.is_emenu_disable === "Y" ||
                                          (customData.is_avl_limit_check ===
                                            "Y" &&
                                            customData.bal_qty === 0)
                                        }
                                      />

                                      <span className="customize-item-citen-name position-absolute end-0 pe-4 text-uppercase d-flex flex-row justify-content-between gap-2">
                                        {formik.values[
                                          customData.modifier_name
                                        ]?.includes(customData.citem_name) && (
                                          <div className="left-mb-1">
                                            <BsDashLg
                                              className="qty-class me-3"
                                              onClick={() =>
                                                decrementCounter(
                                                  customData.modifier_name,
                                                  customData.citem_name
                                                )
                                              }
                                            />
                                            <label className="cart-item-qty">
                                              {getQtyItem(
                                                customData.modifier_name,
                                                customData.citem_name
                                              )}
                                            </label>
                                            <BsPlusLg
                                              className="qty-class ms-3"
                                              onClick={() => {
                                                if (custom.group_limit === 0) {
                                                  return incrementCounter(
                                                    customData.modifier_name,
                                                    customData.citem_name
                                                  );
                                                }

                                                if (
                                                  getQtyItem(
                                                    customData.modifier_name,
                                                    customData.citem_name
                                                  ) +
                                                    getGroupQty(
                                                      customData.modifier_name,
                                                      customData.citem_name
                                                    ) <
                                                  custom.group_limit
                                                ) {
                                                  return incrementCounter(
                                                    customData.modifier_name,
                                                    customData.citem_name
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        )}

                                        <div>
                                          {dineOption === "takeaway"
                                            ? customData?.price_dtls[0]
                                                ?.dine_in_price > 0
                                              ? `+$S${customData?.price_dtls[0]?.takeaway_price}`
                                              : "Free"
                                            : customData?.price_dtls[0]
                                                ?.dine_in_price > 0
                                            ? `+$S${customData?.price_dtls[0]?.dine_in_price}`
                                            : "Free"}
                                        </div>
                                      </span>
                                    </div>
                                  </Form.Group>
                                ))}
                            </>
                          )}
                        </>
                      ))}
                </div>
              </div>

              <div
                className={`${
                  state.item?.itemmaster_menutype_grpdtls &&
                  state.item?.itemmaster_menutype_grpdtls.length > 0
                    ? "position-sticky"
                    : "position-fixed"
                } set-width bottom-0 bg-white cart-button-div ps-3 pe-3 pt-1 pb-3`}
              >
                {/* <div className="d-flex flex-row justify-content-between">
                  <span className="public-sans-font black-text fw-bold font-14 line-height-20 text-uppercase">
                    Item quantity
                  </span>
                  <div className="d-flex align-items-center">
                    <BsDashLg
                      className="qty-class me-3 disable"
                      onClick={decrementCounter}
                    />
                    <label className="public-sans-font fw-bold font-14 black-text line-height-17 text-uppercase">
                      {counter}
                    </label>
                    <BsPlusLg
                      className="qty-class ms-3"
                      onClick={() => {
                        if (
                          cartItemData &&
                          cartItemData.length > 0 &&
                          cartItemData[0].qty >= state.item?.bal_qty &&
                          state.item?.bal_qty !== 0
                        ) {
                        }
                        incrementCounter();
                      }}
                    />
                  </div>
                </div> */}

                <Button
                  className="fill-btn customize-btn mt-1 text-uppercase"
                  type="submit"
                  onClick={(values) => setFormState(values)}
                  disabled={!formik.isValid}
                >
                  Add to cart - S$ {Number(total).toFixed(2)}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomizeDish;
