import React from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import cx from "classnames";

const RecommendationItems = ({ sliderdata, index, placeOrder }) => {
  const isSoldOut = React.useMemo(() => {
    return (
      sliderdata.is_soldout === "Y" ||
      !sliderdata.is_soldout ||
      sliderdata.is_emenu_disable === "Y" ||
      !sliderdata.is_emenu_disable ||
      (sliderdata.is_avl_limit_check === "Y" && sliderdata.bal_qty === 0) ||
      !sliderdata.is_avl_limit_check
    );
  }, []);

  return (
    <div className="item" data-value={index} className="relative px-1">
      <BsPlusCircleFill
        color="var(--primary-color)"
        className={cx("recommendation-add-button", {
          disabled: !isSoldOut,
        })}
        onClick={() => !isSoldOut && placeOrder(sliderdata)}
      />

      <img
        src={`https://ik.imagekit.io/agjl6gve9/hepn/${process.env.REACT_APP_MODE}/${process.env.REACT_APP_MODE}_${sliderdata.item_no}.jpg`}
        className="popular-img"
        alt=""
      />
      {/* <div className="mt-2 text-start">
        <img
          src={`/assets/commonImages/${config.star}.png`}
          alt=""
          className="me-2 ms-2"
        />
        <img src={`/assets/commonImages/${config.chef}.png`} alt="" />
      </div> */}
      <div className="d-grid text-start">
        <label className="recom-text mt-1 text-uppercase text-truncate">
          {sliderdata.item_desc}
        </label>
        <label className="recom-price">
          S$
          {Number(
            sliderdata?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
          ).toFixed(2)}
        </label>
      </div>
    </div>
  );
};

export default RecommendationItems;
