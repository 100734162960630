import { apiCall } from "../../lib/supabase";

export const getProducts = async (brand) => {
  const response = await apiCall
    .from("product")
    .select("*, brand!inner(id, name)")
    .eq("brand.name", brand);

  return response;
};
