import { Image, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import styles from "./css/menuDetail.module.css";

const MenuDetail = ({ show, onHide, data, placeOrder }) => {
  const cartData = useSelector((state) => state.cart);
  const cartItemData = cartData.filter((item) => data.item_no === item.id);
  const addToCart = (data) => {
    placeOrder(data);
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered={true}
      dialogClassName={styles.bottomModal}
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>{data.item_desc}</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <Image
          fluid
          src={`https://ik.imagekit.io/agjl6gve9/hepn/${process.env.REACT_APP_MODE}/${process.env.REACT_APP_MODE}_${data.item_no}.jpg`}
        />
        <p style={{ fontWeight: "600", marginTop: "24px" }}>{data.item_desc}</p>
        <p style={{ fontWeight: "800" }}>
          S$
          {Number(
            data?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
          ).toFixed(2)}
        </p>
        <p style={{ textTransform: "lowercase", margin: 0 }}>
          {data.item_name}
        </p>
        {/* <img
          src={`https://ik.imagekit.io/agjl6gve9/hepn/${process.env.REACT_APP_MODE}/${process.env.REACT_APP_MODE}_${data.item_no}.jpg`}
          className="carousel-cls"
          alt=""
        /> */}
        {/* <span className="menu-price-text mt-2">
          S$
          {Number(
            data?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
          ).toFixed(2)}
        </span> */}
      </Modal.Body>
      <Modal.Footer>
        {data.is_soldout === "Y" ||
        !data.is_soldout ||
        data.is_emenu_disable === "Y" ||
        !data.is_emenu_disable ||
        (data.is_avl_limit_check === "Y" && data.bal_qty === 0) ||
        !data.is_avl_limit_check ? (
          <button className="sold-out-btn mb-2 mt-4" disabled>
            SOLD OUT
          </button>
        ) : (
          <>
            {cartItemData &&
            cartItemData.length > 0 &&
            cartItemData[0].qty >= data.bal_qty &&
            data.bal_qty !== 0 ? (
              <button className="sold-out-btn mb-2 mt-auto flex-end" disabled>
                ADD
              </button>
            ) : (
              <button
                className="border-btn add-cart-btn d-flex justify-content-center align-items-center shaking-button mb-2 mt-auto flex-end"
                onClick={() => addToCart(data)}
              >
                ADD
              </button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default MenuDetail;
