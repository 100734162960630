import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../component/header";
import { BsPlusLg, BsDashLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BiPencil } from "react-icons/bi";
import { Button } from "react-bootstrap";
import { BsFillTrash3Fill } from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import config from "../../config/config";
import { STORAGEKEY } from "../../constants";
import { PostSaleCreate } from "../../services/tableQRServices";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementQuantity,
  decrementQuantity,
  placeOrderSuccess,
  addSalesNo,
  deleteCartItem,
} from "../../redux/cartSlice";
import Recommendation from "./recommendation";
import _ from "lodash";
import { ConfirmationModal } from "./confirmationModal";
import { Spinner } from "react-bootstrap";

function Cart() {
  const { state } = useLocation();
  const cartData = useSelector((state) => state.cart);
  const total = useSelector((state) => state.total);
  const salesNo = useSelector((state) => state.sales_no);
  const dineOption = localStorage.getItem("selectiDineOption");
  const serviceTaxPer =
    localStorage.getItem("selectiDineOption") === "dinein" &&
    config.hasServiceCharge
      ? 0.1
      : 0;
  const [serviceTax, setServiceTax] = useState(
    (total * serviceTaxPer).toFixed(2)
  );
  let gstInclusive = false;
  const [isErrorShow, setIsErrorShow] = useState(false);
  const [taxValue] = useState(9);
  const [showModal, setShowModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [GSTTaxValue, setGSTTaxValue] = useState(
    (((total + total * serviceTaxPer) * 9) / 100).toFixed(2)
  );
  const dispatcher = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [hasServiceCharge, setHasServiceCharge] = useState(false);

  useEffect(() => {
    setGSTTaxValue((((total + total * serviceTaxPer) * 9) / 100).toFixed(2));
    setServiceTax((total * serviceTaxPer).toFixed(2));
    cartData.forEach((cartItem) => {
      gstInclusive = cartItem.item.is_absorbtax ? true : false;
    });
  }, [total]);
  const navigate = useNavigate();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };
  const incrementCounter = (id) => {
    dispatcher(incrementQuantity(id));
  };

  const decrementCounter = (id) => {
    dispatcher(decrementQuantity(id));
  };
  const placeOrder = (id) => {
    const item = cartData.find((item) => item.uuid === id);
    navigate("/customize-dish", {
      state: {
        uuid: item.uuid,
        item: item.item,
        addOnValues: item.addOnValues,
        qty: item.qty,
      },
    });
  };
  const paymentpage = () => {
    let salesDetails = [];
    let i = 1;
    cartData.forEach((cartItem, index) => {
      let price =
        dineOption === "dinein"
          ? cartItem?.item.selling_uom_dtls[0].price_dtls[0].dine_in_price
          : cartItem?.item.selling_uom_dtls[0].price_dtls[0].takeaway_price;
      let parent_sno = i;
      if (cartItem.addOnValues.length) {
        let qty = cartItem.qty;
        while (qty > 0) {
          let sno = i;
          let parent_sno = i;
          salesDetails.push({
            s_no: sno++,
            ds_no: 1,
            parent_sno,
            seat_no: 1,
            qty: 1,
            uom_cf: 1,
            disc_type: "N",
            disc_value: 0,
            disc_amt: 0,
            disc_name: "None",
            pro_disc_amt: 0,
            ...cartItem?.item,
            unit_price: "0.00",
            price: "0.00",
            sub_total: 0,
            svc_amt: "0.000000",
            is_apply_svc: 1,
            tax_amt: "0.000000",
            tax_rate: taxValue,
            tax_value: taxValue,
            is_absorbtax: 0,
            take_away_item: dineOption === "dinein" ? "N" : "Y",
            order_seq: 1,
            order_seq_type: "New",
            order_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
            print_flag: "N",
            item_kds_ready_status: "N",
            item_kds_ready_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
            item_kds_serve_status: "N",
            item_kds_serve_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
            override_f: 0,
            is_addon_enable: "N",
            add_on_name: "",
            c_userid: "WEBORDER",
            c_date: moment().format("YYYY/MM/DD HH:mm:ss"),
            m_userid: "WEBORDER",
            m_date: moment().format("YYYY/MM/DD HH:mm:ss"),
            menu_type: "",
            modifier_name: "",
            ref_1: "",
            ref_2: "",
            ref_3: "",
            ref_4: "",
            new_table_no: null,
            select_item: false,
            combo_qty: 1,
            combo_price: price + "",
            combo_total: price + "",
            checked: false,
            remarks: "",
            $$hashKey: "object:3888",
          });
          const addOnSales = cartItem.addOnValues.map((addOn, subIndex) => {
            return {
              s_no: sno++,
              ds_no: subIndex + 2,
              parent_sno,
              seat_no: 1,
              qty: addOn.modifier_qty,
              uom_cf: 1,
              disc_type: "N",
              disc_value: 0,
              disc_name: "None",
              disc_amt: 0,
              pro_disc_amt: 0,
              category_code: cartItem.item.category_code,
              item_no: addOn.item_no,
              item_name: addOn.citem_name,
              item_desc: addOn.citem_name,
              sku_no: addOn.sku_no,
              uom: addOn.uom,
              unit_price: addOn.price_dtls + "",
              price: addOn.price_dtls + "",
              sub_total: addOn.price_dtls * addOn.modifier_qty,
              svc_amt: (addOn.price_dtls * addOn.modifier_qty * 0.1).toFixed(2),
              is_apply_svc: 1,
              tax_amt: (
                addOn.price_dtls *
                addOn.modifier_qty *
                (taxValue / 100)
              ).toFixed(2),
              tax_rate: taxValue,
              tax_value: taxValue,
              is_absorbtax: 0,
              take_away_item: dineOption === "dinein" ? "N" : "Y",
              order_seq: 1,
              order_seq_type: "New",
              order_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
              print_flag: "N",
              item_kds_ready_status: "N",
              item_kds_ready_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
              item_kds_serve_status: "N",
              item_kds_serve_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
              override_f: 0,
              is_addon_enable: "N",
              add_on_name: "",
              c_userid: "WEBORDER",
              c_date: moment().format("YYYY/MM/DD HH:mm:ss"),
              m_userid: "WEBORDER",
              m_date: moment().format("YYYY/MM/DD HH:mm:ss"),
              menu_type: "",
              modifier_name: addOn.modifier_name,
              ref_1: "",
              ref_2: "",
              ref_3: "",
              ref_4: "",
              new_table_no: null,
              select_item: false,
              combo_qty: 0,
              combo_price: 0,
              combo_total: 0,
              checked: false,
              remarks: "",
              $$hashKey: "object:3888",
            };
          });
          i = sno;
          salesDetails = [...salesDetails, ...addOnSales];
          qty--;
          parent_sno++;
        }
      } else {
        salesDetails.push({
          s_no: i++,
          ds_no: 1,
          parent_sno,
          seat_no: 1,
          qty: cartItem.qty,
          uom_cf: 1,
          disc_type: "N",
          disc_value: 0,
          disc_amt: 0,
          disc_name: "None",
          pro_disc_amt: 0,
          ...cartItem?.item,
          unit_price: price + "",
          price: price + "",
          sub_total: price * cartItem.qty,
          svc_amt: (price * cartItem.qty * 0.1).toFixed(2),
          is_apply_svc: 1,
          tax_amt: (price * cartItem.qty * (taxValue / 100)).toFixed(2),
          tax_rate: taxValue,
          tax_value: taxValue,
          is_absorbtax: 0,
          take_away_item: dineOption === "dinein" ? "N" : "Y",
          order_seq: 1,
          order_seq_type: "New",
          order_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
          print_flag: "N",
          item_kds_ready_status: "N",
          item_kds_ready_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
          item_kds_serve_status: "N",
          item_kds_serve_datetime: moment().format("YYYY/MM/DD HH:mm:ss"),
          override_f: 0,
          is_addon_enable: "N",
          add_on_name: "",
          c_userid: "WEBORDER",
          c_date: moment().format("YYYY/MM/DD HH:mm:ss"),
          m_userid: "WEBORDER",
          m_date: moment().format("YYYY/MM/DD HH:mm:ss"),
          menu_type: "",
          modifier_name: "",
          ref_1: "",
          ref_2: "",
          ref_3: "",
          ref_4: "",
          new_table_no: null,
          select_item: false,
          combo_qty: 0,
          combo_price: 0,
          combo_total: 0,
          checked: false,
          remarks: "",
          $$hashKey: "object:3888",
        });
      }
    });
    const body = [
      {
        qr_code: localStorage.getItem(STORAGEKEY.QR_CODE),
        order_mode: config.order_mode,
        qr_type: config.qr_type,
        comp_code: "01",
        store_name: localStorage.getItem(STORAGEKEY.STORE_NAME),
        doc_date: moment().format("YYYY/MM/DD HH:mm:ss"), // example 2021/01/01 00:00:00
        service_type: dineOption === "dinein" ? "E" : "T",
        service_type_info: dineOption === "dinein" ? "Dine In" : "Take Away",
        // localStorage.getItem("selectiDineOption") === "takeaway"
        //   ? "Takeaway"
        //   : "Dine In",
        order_status_id: "N",
        order_status_desc: "New",
        kitchen_status_id: "P",
        kitchen_status_desc: "In Progress",
        sub_total: total,
        total_svc: serviceTax,
        total_tax: GSTTaxValue,
        net_amt: Number(total) + Number(serviceTax) + Number(GSTTaxValue),
        table_no: localStorage.getItem(STORAGEKEY.TABLE_NO),
        sales_service_dtls: [
          {
            service_name: "SERVICE CHARGE",
            service_by: "P",
            service_value: 10,
            sales_amt: total,
            service_amt: serviceTax,
          },
        ],
        sales_payment_dtls: [],
        sales_other_info: "",
        payment_register_name: config.register_no,
        payment_shift_code: "SHIFT1",
        table_transfer: "N",
        c_date: moment().format("YYYY/MM/DD HH:mm:ss"),
        m_userid: "",
        m_date: moment().format("YYYY/MM/DD HH:mm:ss"),
        disc_type: "N",
        disc_value: 0,
        disc_amt: 0,
        total_disc: "0.00",
        round_adj_amt: "0.00",
        absorb_tax: "Y",
        register_name: config.register_no,
        shift_code: "SHIFT1",
        absorb_tax_info: "",
        table_transfer_sno: "",
        sales_no: "",
        customer_code: "",
        cust_addr_s_no: "",
        customer_name: "",
        card_no: "",
        dob: "",
        contact_no: "",
        email: "",
        address: "",
        postal_code: "",
        disc_name: "None",
        tips_amt: 0,
        total_tender_amt: "0.00",
        change_amt: 0,
        no_of_pax: 1,
        remarks: "",
        del_driver: "",
        ref_1: "",
        ref_2: "",
        ref_3: "",
        ref_4: "",
        ref_5: "",
        order_from: "TQR",
        c_userid: "",
        sales_dtls: salesDetails,
      },
    ];
    console.log("body", body);
    PostSaleCreate(body)
      .then(async (res) => {
        const result = res?.data?.data[0];
        if (result.result === "SUCCESS") {
          dispatcher(addSalesNo(result.sales_no));
          dispatcher(
            placeOrderSuccess({
              serviceTax,
              GSTTaxValue,
              order_status: "complete",
            })
          );
          setShowModal(false);
          setDisableButton(false);

          if (config.isPaymentShow) {
            setIsLoading(true);
            const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);
            const tableNo = localStorage.getItem(STORAGEKEY.TABLE_NO);
            window.location.href = `${process.env.REACT_APP_QLUB_PAYMENT}/?order_id=${result.sales_no}&restaurant_name=${storeName}&table_id=${tableNo}`;
          } else {
            navigate("/thankyou", {
              state: { orderData: cartData, serviceTax, GSTTaxValue, total },
            });
          }
        } else {
          setIsErrorShow(true);
          setShowModal(false);
          setDisableButton(false);
        }
      })
      .catch((err) => {});
  };
  const deleteCart = (cartItems) => {
    dispatcher(deleteCartItem(cartItems));
  };
  const redirectHomepage = () => {
    navigate("/home");
  };

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Cart" />
          <div className="inner-div main-div position-absolute w-100">
            <div className="position-fixed w-100">
              <div className="d-flex  ps-4 pb-2 pe-3 pt-3 bg-white">
                <label className="recommendation-text">
                  RECOMMENDATIONS FOR YOU
                </label>
              </div>
              <div className="pt-2 bg-white pb-3 ps-2 pe-2">
                <Recommendation responsive={responsive} />
              </div>
            </div>
            {cartData && cartData.length !== 0 ? (
              <>
                <div
                  className="d-flex justify-content-between ps-4 pb-2 pe-3 pt-3"
                  style={{ marginTop: "190px" }}
                >
                  <label className="order-summary-text">ORDER SUMMARY</label>
                </div>
                {cartData &&
                  cartData.length > 0 &&
                  cartData.map((cartItem, index) => (
                    <div className="bg-white" key={index}>
                      <div className="d-flex justify-content-between ps-4 pb-2 pe-3 pt-3">
                        <div className="d-grid text-start">
                          <label className="cart-item-text mb-2 text-uppercase">
                            {cartItem.item.item_desc}
                          </label>
                          {cartItem.addOnValues &&
                            cartItem.addOnValues.length > 0 &&
                            cartItem.addOnValues.map((custom, i) => (
                              <>
                                <label
                                  key={i}
                                  className="modifier-text mb-1 text-uppercase"
                                >
                                  {custom.modifier_name}: {custom.citem_name} (
                                  {custom.modifier_qty})
                                </label>
                              </>
                            ))}

                          <label className="cart-item-price mb-1 mt-2">
                            S${Number(cartItem.price * cartItem.qty).toFixed(2)}
                          </label>
                        </div>
                        <div>
                          <img
                            src={`https://ik.imagekit.io/agjl6gve9/hepn/${process.env.REACT_APP_MODE}/${process.env.REACT_APP_MODE}_${cartItem.item.item_no}.jpg`}
                            alt=""
                            className="cart-img"
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between ps-4 pb-2 pe-3 align-items-center">
                        <div className="d-flex">
                          <BsFillTrash3Fill
                            className="material-icons me-2"
                            onClick={() => deleteCart(cartItem.uuid)}
                          />

                          {cartItem.item.itemmaster_menutype_grpdtls === "" ? (
                            <div className="d-flex">
                              {/* <label className="public-sans-font fw-normal font-16 black-text line-height-17 mb-1 mt-2 edit-btn-disable">
                              <BiPencil className="font-18 pe-1" />
                              Edit
                            </label> */}
                            </div>
                          ) : (
                            <label
                              className="mt-1 edit-btn d-flex justify-content-center text-uppercase"
                              onClick={() => placeOrder(cartItem.uuid)}
                            >
                              <BiPencil className="font-18 pe-1" />
                            </label>
                          )}
                        </div>
                        <div className="mb-1 mt-2">
                          <BsDashLg
                            className="qty-class me-3"
                            onClick={() => decrementCounter(cartItem.uuid)}
                          />
                          <label className="cart-item-qty">
                            {cartItem.qty}
                          </label>
                          <BsPlusLg
                            className="qty-class ms-3"
                            onClick={() => {
                              if (
                                !(
                                  cartItem.qty >= cartItem.item.bal_qty &&
                                  cartItem.item.bal_qty !== 0
                                )
                              ) {
                                incrementCounter(cartItem.uuid);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                <div className="d-flex  ps-4 pb-2 pe-3 pt-3">
                  <label className="payment-summart-text">
                    PAYMENT SUMMARY
                  </label>
                </div>
                <div className="bg-white d-flex justify-content-between p-4 text-start">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td className="sub-total-text pb-2">SUBTOTAL</td>
                        <td className="sub-total-text pb-2 text-end">
                          S${Number(total).toFixed(2)}
                        </td>
                      </tr>

                      {config.hasServiceCharge && (
                        <>
                          <tr>
                            <td className="sub-total-text pb-2">
                              SERVICE CHARGE (10%)
                            </td>
                            <td className="sub-total-text pb-2 text-end">
                              S${Number(serviceTax).toFixed(2)}
                            </td>
                          </tr>
                        </>
                      )}

                      <tr>
                        <td className="sub-total-text pb-2">
                          GST {taxValue}% {gstInclusive ? "Inclusive" : ""}
                        </td>
                        <td className="sub-total-text pb-2 text-end">
                          S${Number(GSTTaxValue).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <hr className="dashed-border" />
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="public-sans-font fw-bold font-16 black-text line-height-21 pb-2 pt-2">
                          TOTAL
                        </td>
                        <td className="public-sans-font fw-bold font-16 black-text line-height-21 pb-2 pt-2 text-end">
                          S$
                          {(
                            Number(total) +
                            Number(serviceTax) +
                            Number(GSTTaxValue)
                          ).toFixed(2)}
                        </td>
                      </tr> */}
                      {/* <tr>
                        <td
                          colSpan={2}
                          className="public-sans-font fw-normal font-16 black-text line-height-17 pt-2"
                        >
                          If you continue, you accept our{" "}
                          <Link
                            to="/profile"
                            className="privact-txt text-decoration-underline"
                          >
                            Privacy Policy
                          </Link>{" "}
                          and our{" "}
                          <Link
                            to="/profile"
                            className="privact-txt text-decoration-underline"
                          >
                            {" "}
                            Terms of Services
                          </Link>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>

                <div style={{ flex: 1 }}></div>

                <div className="position-sticky set-width bottom-0 bg-white cart-button-div pt-1 pb-1 ps-3 pe-3">
                  {isErrorShow && (
                    <p className="error-text text-danger text-start">
                      Please ask the counter to generate new Table QR Code and
                      try again
                    </p>
                  )}

                  {/* <Button
                    className="fill-green-btn cart-btn mt-2 public-sans-font fw-bold font-16 line-height-21 letter-spacing-02"
                    type="submit"
                    onClick={() => setShowModal(true)}
                  >
                    Place Order
                  </Button> */}

                  <button
                    onClick={() => setShowModal(true)}
                    className="w-100 fill-btn sticky-btn position-sticky mb-2 mt-2 me-4"
                  >
                    <div className="d-flex align-items-center justify-content-center w-100 text-white">
                      <span className="place-order-text mx-5">PLACE ORDER</span>
                      <span className="place-order-text rounded-circle me-2 px-2 py-1 mx-5">
                        S$
                        {(
                          Number(total) +
                          Number(serviceTax) +
                          Number(GSTTaxValue)
                        ).toFixed(2)}
                      </span>
                    </div>
                  </button>

                  <ConfirmationModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setDisableButton={setDisableButton}
                    disableButton={disableButton}
                    paymentpage={paymentpage}
                  />
                </div>
              </>
            ) : (
              <div style={{ marginTop: "300px", height: "60vh" }}>
                {isLoading && (
                  <Spinner
                    animation="border material-icons"
                    className="loader-position"
                  />
                )}
                <img
                  src={`/assets/commonImages/${config.EmptyImage}.png`}
                  alt="empty Screen"
                  className="mt-5 pt-4"
                />
                <div className="d-grid mt-3 me-3 ms-3">
                  <label className="empty-cart-text mb-1 text-uppercase">
                    Your Cart is Empty
                  </label>
                  {/* <label className="public-sans-font fw-bold font-16 black-text line-height-20 ms-3 me-3 text-uppercase">
                    Looks like you haven't made your choice yet
                  </label> */}
                </div>
                <Button
                  className="fill-btn menu-btn w-50 mt-4"
                  type="submit"
                  onClick={redirectHomepage}
                >
                  Explore our Menu
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Cart;
