import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
    total: 0,
    sales_no: "",
    order_details: [],
  },
  reducers: {
    addToCart: (state, action) => {
      const itemInCart = state.cart.find(
        (item) =>
          item.id === action.payload.id &&
          item.addOnValues.length === action.payload.addOnValues.length &&
          _.isEqual(
            _.map(item.addOnValues, "item_no"),
            _.map(action.payload.addOnValues, "item_no")
          )
      );
      if (itemInCart) {
        itemInCart.qty++;
        state.total += action.payload.price;
      } else {
        state.cart.push({ uuid: uuidv4(), ...action.payload });
        state.total += action.payload.price * action.payload.qty;
      }
    },
    updateToCart: (state, action) => {
      const filterItems = state.cart.filter(
        (item) => item.uuid !== action.payload.uuid
      );

      const removeItem = state.cart.find((item) => {
        return item.uuid === action.payload.uuid;
      });
      state.cart = filterItems;
      if (removeItem) {
        state.total -= removeItem.qty * removeItem.price;
      }

      const itemInCart = state.cart.find(
        (item) =>
          item.id === action.payload.id &&
          item.addOnValues.length === action.payload.addOnValues.length &&
          _.isEqual(
            _.map(item.addOnValues, "item_no"),
            _.map(action.payload.addOnValues, "item_no")
          )
      );

      if (itemInCart) {
        itemInCart.qty++;
        state.total += action.payload.price;
      } else {
        state.cart.push({ uuid: uuidv4(), ...action.payload });
      }
      state.total += action.payload.price * action.payload.qty;
    },
    incrementQuantity: (state, action) => {
      const item = state.cart.find((item) => item.uuid === action.payload);
      item.qty++;

      state.total += item.price;
    },
    decrementQuantity: (state, action) => {
      const item = state.cart.find((item) => item.uuid === action.payload);
      if (item.qty === 1) {
        const removeItem = state.cart.filter(
          (item) => item.uuid !== action.payload
        );
        state.total -= item.price;
        state.cart = removeItem;
      } else {
        item.qty--;
        state.total -= item.price;
      }
    },
    removeItem: (state, action) => {
      const removeItem = state.cart.filter(
        (item) => item.uuid !== action.payload
      );
      state.total -= removeItem.price;
      state.cart = removeItem;
    },
    deleteCartItem: (state, action) => {
      const item = state.cart.find((item) => item.uuid === action.payload);
      const removePrice = item.price * item.qty;
      const removedCartItem = state.cart.filter(
        (i) => i.uuid !== action.payload
      );
      state.total -= removePrice;
      state.cart = removedCartItem;
    },
    placeOrderSuccess: (state, action) => {
      state.order_details.push({
        items: state.cart,
        subTotal: state.total,
        time: new Date(),
        ...action.payload,
      });
      state.cart = [];
      state.total = 0;
    },
    clearCart: (state, action) => {
      state.cart = [];
      state.total = 0;
      state.order_details = [];
      state.sales_no = "";
    },
    addSalesNo: (state, action) => {
      state.sales_no = action.payload;
    },
  },
});

export const cartReducer = cartSlice.reducer;
export const {
  addToCart,
  updateToCart,
  incrementQuantity,
  decrementQuantity,
  removeItem,
  deleteCartItem,
  placeOrderSuccess,
  addSalesNo,
} = cartSlice.actions;
