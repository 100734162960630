import React, { useEffect, useRef, useState } from "react";
import Header from "../../component/header";
import CarouselBanner from "./CarouselBanner";
import Scrollspy from "react-scrollspy-ez";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineShoppingCart } from "react-icons/md";
import { SlBag } from "react-icons/sl";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Button, Spinner } from "react-bootstrap";
// import TimeKeeper from "react-timekeeper";
// import { CustomDialog } from "react-st-modal";
import config from "../../config/config";
import {
  storeMenuButton,
  storeMenuItems,
  storeStockList,
} from "../../services/tableQRServices";
import _ from "lodash";
import MenuItems from "./MenuItems";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCategories } from "../../services/supabase/category";
import MenuDetail from "./MenuDetail";
import { getProducts } from "../../services/supabase/product";

function Home() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [categories, setCategories] = useState([]);

  const [isBottomOpen, setIsBottomOpen] = useState(true);
  const [enableOverlay, setEnableOverlay] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [time, setTime] = useState("12:34pm");
  const [menuCategories, setMenuCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [searchedMenuItems, setSearchedMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedMenuItem] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);

  const cartItems = useSelector((state) => state.cart);
  // get items from the cart and then read the qty of each item
  // sum the items to get cart items count each time an item is added to the cart
  const cartItemsCount = cartItems.reduce((acc, item) => acc + item.qty, 0);

  useEffect(() => {
    setIsBottomOpen(false);
    if (config.diningOption === "DineInOnly") {
      // setIsBottomOpen(false);
      localStorage.setItem("selectiDineOption", "dinein");
    } else if (config.diningOption === "TakeAwayOnly") {
      // setIsBottomOpen(false);
      localStorage.setItem("selectiDineOption", "takeaway");
    }

    if (config.isDineOption) {
      let getStorageOption = localStorage.getItem("selectiDineOption");
      if (getStorageOption === "" || getStorageOption === null) {
        setIsBottomOpen(true);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      // Clear the listener on component destroyed
      if (menuRef && menuRef.current && menuRef.current.removeEventListener) {
        menuRef.current.removeEventListener("scroll", calculateScrollFn);
      }
    };
  }, []);

  const subTotal = useSelector((state) => state.total);
  const dispatcher = useDispatch();

  const itemsListAndCategory = () => {
    setIsLoading(true);
    storeStockList()
      .then((res) => {
        const result = res?.data?.data[0]?.output;
        const resultParse = JSON.parse(result);
        // let categoryCodes = [];
        // for (const category of resultParse.filter(
        //   (category) => category.avl_type === "C"
        // )) {
        //   categoryCodes.push(category.item_category);
        // }
        let resultItemsParsed = resultParse.filter(
          (category) => category.avl_type === "I"
        );
        menuButtonFunction(categories, resultItemsParsed, resultParse);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const menuButtonFunction = (
    categoryCodes,
    resultItemsParsed,
    resultParse
  ) => {
    storeMenuButton()
      .then((res) => {
        const categoryMenuButton = res?.data?.data[0]?.output;
        let categoryMainMenu = categoryMenuButton.filter(
          (menuItem) => menuItem.category && menuItem.category.length > 0
          // (menuItem) => menuItem.root_category_code === "MAIN"
          // filter menuItem.items length > 0
          // (menuItem) => menuItem.items.length > 0
        );
        let categoryMainMenuButton = [];
        for (const catItems of categoryMainMenu) {
          categoryMainMenuButton.push(...catItems.category);
        }
        if (categoryMainMenuButton && categoryMainMenuButton.length > 0) {
          let categoriesIndex = categoryMainMenuButton;
          // categoryMainMenuButton = categoryMainMenuButton[0];
          // let categoriesIndex = categoryMainMenuButton.category;
          // ? Sort on sequences and filter on start and end time
          categoriesIndex = categoriesIndex
            .sort((a, b) => a.course_seq - b.course_seq)
            .filter(
              (category) =>
                category.start_time <= moment().format("HH:mm:ss") &&
                category.end_time > moment().format("HH:mm:ss")
            );

          const categoregoryFromApi = categoryMenuButton
            .filter((category) => category.items !== "")
            .map((category) => {
              return category.root_category_code;
            });

          // make sure categories codes has items on it
          categoryCodes = _.intersection(categoryCodes, categoregoryFromApi);
          // for (const category of categoriesIndex) {
          //   categoryCodes.push(category.category_code);
          // }
          let menuItemsArray = [];
          for (const category of categoryCodes) {
            const catItem = categoryMenuButton.filter(
              (catItems) => catItems.root_category_code === category
            );
            if (
              catItem &&
              catItem.length > 0 &&
              catItem[0].items &&
              catItem[0].items.length > 0
            ) {
              menuItemsArray.push(...catItem[0].items);
            }
          }
          // ? Intersect items from stock and button api
          let intersectCategory = _.intersectionBy(
            resultItemsParsed,
            menuItemsArray,
            (obj) => obj.item_category || obj.item_no
          );
          // ? Intersect items from with intersected items and button api items
          intersectCategory = Object.values(
            _.merge(
              _.keyBy(intersectCategory, "item_category"),
              _.keyBy(menuItemsArray, "item_no")
            )
          ).filter(
            (item) =>
              item.is_emenu_disable === "N" &&
              item.start_time <= moment().format("HH:mm:ss") &&
              item.end_time > moment().format("HH:mm:ss")
          );
          setMenuCategories(categoryCodes);
          menuItemsFunction(intersectCategory, categoryCodes, resultParse);
        }
      })
      .catch(() => {});
  };

  const menuItemsFunction = (intersectCategory, categoryCodes, resultParse) => {
    storeMenuItems()
      .then((res) => {
        const result = res?.data?.data[0]?.output;
        const filterItemMaster = result.filter(
          (item) =>
            item.itemmaster_menutypedtls &&
            item.itemmaster_menutypedtls.length > 0
        );

        const stockedCheckedItem = [];
        if (filterItemMaster && filterItemMaster.length > 0) {
          for (const itemMaster of filterItemMaster) {
            let itemmaster_menutypedtls = [];
            for (const typeDtls of itemMaster.itemmaster_menutypedtls) {
              const modifierQty = resultParse.filter(
                (itemCategory) => itemCategory.item_no === typeDtls.citem_no
              );

              if (modifierQty && modifierQty.length > 0) {
                itemmaster_menutypedtls.push({
                  ...typeDtls,
                  is_soldout: modifierQty[0].is_soldout,
                  is_emenu_disable: modifierQty[0].is_emenu_disable,
                  is_avl_limit_check: modifierQty[0].is_avl_limit_check,
                  bal_qty: modifierQty[0].bal_qty,
                });
              } else {
                itemmaster_menutypedtls.push({ ...typeDtls });
              }
            }

            stockedCheckedItem.push({ ...itemMaster, itemmaster_menutypedtls });
          }
        }
        const filterNoItemMaster = result.filter(
          (item) =>
            !item.itemmaster_menutypedtls ||
            item.itemmaster_menutypedtls.length <= 0
        );

        let intersectItems = _.intersectionBy(
          intersectCategory,
          [...filterNoItemMaster, ...stockedCheckedItem],
          (obj) => obj.item_category || obj.item_no
        ).filter((item) => item.is_active);

        intersectItems = Object.values(
          _.merge(
            _.keyBy(intersectCategory, "item_category"),
            _.keyBy([...filterNoItemMaster, ...stockedCheckedItem], "item_no")
          )
        )
          .filter(
            (item) =>
              item.is_active && categoryCodes.includes(item.category_code)
          )
          .filter((item) => item.is_emenu_disable === "N");

        // if menu item_no is in topPicksId, the then set is_top_pick to true
        intersectItems = intersectItems.map((item) => {
          if (topPicks.includes(item.item_no)) {
            return { ...item, is_top_pick: true };
          }
          return { ...item };
        });

        const sortAlphabetically = intersectItems.sort((a, b) =>
          a.item_desc.localeCompare(b.item_desc)
        );
        const sortByCategory = sortAlphabetically.sort(
          (a, b) =>
            categoryCodes.indexOf(a.category_code) -
            categoryCodes.indexOf(b.category_code)
        );

        setMenuItems(sortByCategory);
        setIsLoading(false);
      })

      .catch((err) => {
        setIsLoading(false);
      });
  };

  const fetchSupabaseCategories = async () => {
    setIsLoading(true);
    const resp = await getCategories();
    setCategories(resp.data.map((item) => item.name.toUpperCase()));
  };
  useEffect(() => {
    fetchSupabaseCategories();
  }, []);
  useEffect(() => {
    if (categories.length !== 0) {
      itemsListAndCategory();
    }
  }, [categories]);

  // fetch products from supabase
  const [topPicks, setTopPicks] = useState([]);
  const fetchSupabaseProducts = async (brand) => {
    setIsLoading(true);
    const resp = await getProducts(brand);
    if (resp.data.length !== 0) {
      // look for items where is_top_pick is true
      const topPicks = resp.data.filter((item) => item.is_top_pick);
      const topPicksIds = topPicks.map((item) => item.pos_item_no);
      console.log("top picks", topPicksIds);
      setTopPicks(topPicksIds);
    }
  }
  useEffect(() => {
    const brand = process.env.REACT_APP_MODE;
    fetchSupabaseProducts(brand);
  }, []);
  

  let menuItem, menuDish;
  menuItem = menuCategories;
  menuDish = searchedMenuItems && showSearchBar ? searchedMenuItems : menuItems;
  const placeOrder = (dishDetail) => {
    if (dishDetail.itemmaster_menutype_grpdtls === "") {
      dispatcher(
        addToCart({
          id: dishDetail.item_no,
          addOnValues: [],
          item: dishDetail,
          qty: 1,
          price: dishDetail.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price,
        })
      );
      toast.success(`${dishDetail.item_name}  ADDED TO CART`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      // navigate("/cart");
    } else {
      navigate("/customize-dish", {
        state: { item: dishDetail },
      });
    }
  };
  const toggleBottom = () => {
    if (
      localStorage.getItem("selectiDineOption") === "dinein" ||
      localStorage.getItem("selectiDineOption") === "takeaway"
    ) {
      setIsBottomOpen((prev) => !prev);
    }
  };

  const openDiningOption = () => {
    setIsBottomOpen(true);
  };

  const selectDining = (selectionOption) => {
    localStorage.setItem("selectiDineOption", selectionOption);
    // set current cart to dine in
    setIsBottomOpen(false);
  };
  const searchFunction = (values) => {
    if (values && values.length > 0) {
      setSearchedMenuItems(
        menuItems.filter(
          (item) =>
            item.item_name.includes(values) ||
            item.item_name.toLowerCase().includes(values.toLowerCase()) ||
            item.item_name.toUpperCase().includes(values.toUpperCase())
        )
      );
    } else {
      // itemsListAndCategory();
      setSearchedMenuItems(menuItems);
    }
  };
  const handleSearchClick = () => {
    setShowSearchBar(!showSearchBar);
  };

  const [initialized, setInitialized] = useState(false);
  const menuRef = useRef();

  let pendingAction;
  const calculateScrollFn = () => {
    if (pendingAction) {
      clearTimeout(pendingAction);
      pendingAction = false;
    }
    pendingAction = setTimeout(() => {
      const activeMenuLi = document.querySelector(".spyItemClass.active");
      if (!activeMenuLi) {
        return;
      }
      const menuContainer = activeMenuLi.parentNode;

      const elementRect = activeMenuLi.getBoundingClientRect();
      const containerRect = menuContainer.getBoundingClientRect();

      let scrollGap;
      if (elementRect.top < containerRect.top) {
        scrollGap = elementRect.top - containerRect.top;
      }
      if (elementRect.bottom > containerRect.bottom) {
        scrollGap = elementRect.bottom - containerRect.bottom;
      }
      if (scrollGap) {
        menuContainer.scrollBy(0, scrollGap);
      }
    }, 50);
  };

  function initScrollListener() {
    if (initialized) {
      return;
    }
    if (!menuRef || !menuRef.current) {
      return;
    }
    menuRef.current.removeEventListener("scroll", calculateScrollFn);
    menuRef.current.addEventListener("scroll", calculateScrollFn);
    setInitialized(true);
  }
  const toggleDetailModal = (data = {}) => {
    setSelectedMenuItem(data);
    if (showDetailModal) {
      setShowDetailModal(false);
    } else {
      setShowDetailModal(true);
    }
  };
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header
            title="home"
            showSearchBar={showSearchBar}
            handleSearchClick={handleSearchClick}
            searchFunction={searchFunction}
            openDiningOption={openDiningOption}
          />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div
            className="main-div home-div position-absolute w-100"
            ref={menuRef}
          >
            {config.home_banner_enabled && <CarouselBanner />}
            <div className="top-border d-flex flex-column">
              {!initialized && initScrollListener()}
              {isLoading ? (
                <Spinner animation="border material-icons align-self-center position-absolute top-50" />
              ) : (
                <>
                  {menuItem && menuItem.length > 0 ? (
                    <>
                      <Scrollspy
                        ids={menuItem}
                        itemContainerClassName="scrollSpyContainer top-0 end-0 p-0 menu-cls d-flex flex-row"
                        activeItemClassName="active menuitem-active"
                        itemClassName="spyItemClass text-center"
                        offset={100}
                        includeParentClasses={true}
                      />
                      <div
                        id="menuitems"
                        className="menuitems w-100 pt-2 d-flex xs-col-6 sm-col-6 flex-wrap"
                      >
                        {menuDish.length > 0 ? (
                          <>
                            {menuDish.map((menuDishes, index) => {
                              return (
                                <MenuItems
                                  key={`menuItem-${index}`}
                                  menuDishes={menuDishes}
                                  placeOrder={placeOrder}
                                  onDetailOpen={toggleDetailModal}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <div>
                            <img
                              src={`/assets/commonImages/${config.search}.png`}
                              alt=""
                              className="mt-5"
                            />
                            <div className="d-grid">
                              <label className="no-result-text black-text">
                                No Result
                              </label>
                              <label className="no-result-desc text-black">
                                Sorry we couldn't find any result, try another
                                keyword.
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div>
                      <img
                        src={`/assets/commonImages/${config.search}.png`}
                        alt=""
                        className="mt-5"
                      />
                      <div className="d-grid">
                        <label className="no-result-text black-text">
                          No Result
                        </label>
                        <label className="no-result-desc text-black">
                          Sorry we couldn't find any result, try another
                          keyword.
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="home-bottom-div">
                    <Link to="/cart">
                      <button className="viewcart-btn w-60 fill-btn mb-2 me-4 ms-4 pulsing-button">
                        <>
                          <div className="d-flex justify-content-space-evenly pt-1 flex-wrap">
                            <div className="col">
                              <MdOutlineShoppingCart className="shopping-cart-icon" />
                            </div>
                            <div className="col fw-bold text-start">
                              Items: {cartItemsCount}
                            </div>
                            <div className="cart-price col">
                              S$ {Number(subTotal).toFixed(2)}
                            </div>
                          </div>
                        </>
                        {/* ) : (
                        <>
                          <div
                            className="cart-btn-gochiso pt-1"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <i
                              className="text-white material-icons py-2 px-3"
                              style={{ fontSize: "24px", flexShrink: 0 }}
                            >
                              shopping_cart
                            </i>
                            <label
                              className="public-sans-font font-14 fw-bold line-height-21 text-white pe-4"
                              style={{ flexGrow: 1, textAlign: "right" }}
                            >
                              S$ {Number(subTotal).toFixed(2)}
                            </label>
                          </div>
                        </>
                      )} */}
                      </button>
                    </Link>
                  </div>
                </>
              )}
            </div>

            <Drawer
              open={isBottomOpen}
              onClose={toggleBottom}
              direction="bottom"
              size={300}
              enableOverlay={enableOverlay}
            >
              <div>
                <label className="d-flex pt-3 ps-3 select-option-text text-uppercase">
                  Select your dining experience
                </label>
                <div className="option-div me-3 ms-3 mt-4 pt-3 pb-3">
                  <div className="dining-select-div">
                    <div className="option-icon">
                      <span className="material-icons pt-3"> restaurant </span>
                    </div>
                    <label className="option-text mt-3 ms-3 text-uppercase">
                      Dine in
                    </label>
                    <Button
                      className="fill-btn cart-btn dining-btn mt-2 position-absolute end-0 me-4 mt-2 text-uppercase"
                      type="submit"
                      onClick={() => selectDining("dinein")}
                    >
                      Select
                    </Button>
                  </div>
                </div>
                <div className="option-div me-3 ms-3 mt-4 pt-3 pb-3">
                  <div className="dining-select-div">
                    <div className="option-icon">
                      <SlBag className="material-icons mt-3 font-18" />
                    </div>
                    <label className="option-text mt-3 ms-3 text-uppercase">
                      Takeaway
                    </label>
                    <Button
                      className="fill-btn cart-btn dining-btn mt-2 position-absolute end-0 me-4 mt-2 text-uppercase"
                      type="submit"
                      onClick={async () => {
                        localStorage.setItem("selectiDineOption", "takeaway");
                        setIsBottomOpen(false);
                        // const result = await CustomDialog(
                        //   <TimeKeeper
                        //     time={time}
                        //     onChange={(newTime) => setTime(newTime.formatted12)}
                        //     switchToMinuteOnHourSelect
                        //     // disabledTimeRange={{ from: "4:20", to: "20:45" }}
                        //   />,
                        //   {
                        //     title: "Custom Dialog",
                        //     showCloseIcon: true,
                        //   }
                        // );
                      }}
                    >
                      Select
                    </Button>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </div>
      {showDetailModal ? (
        <MenuDetail
          show={showDetailModal}
          onHide={toggleDetailModal}
          data={selectedItem}
          placeOrder={placeOrder}
        />
      ) : null}
    </>
  );
}
export default Home;
