import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import config from "../../config/config";
const Thankyou = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleClick = () => {
    navigate("/order-details", {
      state: {
        orderData: {
          items: state.orderData,
          GSTTaxValue: state.GSTTaxValue,
          serviceTax: state.serviceTax,
          subTotal: state.total,
        },
      },
    });
  };
  const handleClickHome = () => {
    navigate("/home");
  };

  // clear selectiDineOption from local storage
  localStorage.removeItem("selectiDineOption");

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="inner-div main-div position-absolute w-100">
            <div className="pt-5 mt-4">
              <img
                src={`/assets/commonImages/${config.flower}.png`}
                alt=""
                className="mt-5"
              />
            </div>
            <div className="d-grid">
              <label className="thank-text mt-4 text-center">
                Thank You!
              </label>
              {process.env.REACT_APP_MODE !== "pavilion" ? (
                <>
                  <label className="p-2 mt-2 thank-desc text-center">
                    Your order has been sent to the kitchen. <br />
                    Please make payment at the cashier.
                  </label>
                </>
              ) : (
                <label className="p-2 mt-2 text-center">
                  <>Your order will failing soon</>
                </label>
              )}
            </div>
            <Button
              className="thank-btn mt-4 me-4 ms-4"
              type="submit"
              onClick={handleClickHome}
            >
              Go back to Menu
            </Button>
            <Button
              className='thank-btn mt-4 me-4 ms-4'
              type="submit"
              onClick={handleClick}
            >
              View Order Detail
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyou;
