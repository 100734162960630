import { Button, Modal } from "react-bootstrap";

export const ConfirmationModal = ({
  showModal,
  setShowModal,
  paymentpage,
  setDisableButton,
  disableButton,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setDisableButton(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to place this order?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="cancel-btn w-auto cart-btn"
          onClick={() => setShowModal(false)}
        >
          Cancel
        </Button>
        <Button
          className="fill-btn w-auto cart-btn"
          onClick={paymentpage}
        >
          Place Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
