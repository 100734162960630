export const DevURL = process.env.REACT_APP_BACKEND_BASE_URL;

// ? Authentication URL
export const GET_SESSION_ID = "/GetSessionID/01";
export const GENERATE_QR_CODE = "/postPosTableGenerateQR/create/01";
export const QR_CODE_VALIDATION = "/GetPOSTableQRValidation/01";
export const GET_HOME_CATEGORY = "/GetPosFullItemList/01";
export const GET_STORE_STOCK_LIST = "/GetPosStroreStockList/01";
export const GET_MENU_BUTTON_LIST = "/GetPOSMenuButton/01";
export const GET_GST = "/GetTax/01";
export const POST_ORDER = "/PosOrder/create/01";
export const GET_SERVICE_CHARGE = "/Getservicescharges/01";

export const URL = DevURL;


// https://mannapot-tqr-api.evolut.com.sg/api/ExternalGetStroreStockList/01?sessionid=967E0BFA2838CEC3D43785A515A14516163AE4F8&storename=Manna Bistro and Grill&dayname=Friday&logindate=2023-04-27