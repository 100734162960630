import React, { useEffect, useState } from "react";
import { AnimateOnChange } from "react-animation";
import { useNavigate } from "react-router-dom";
import {
  QRCodeValidation,
  generateQRCode,
  userSessionId,
} from "../../services/tableQRServices";
import { STORAGEKEY } from "../../constants";
import moment from "moment";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

function SplashScreen() {
  const navigate = useNavigate();
  let params = window.location.search;
  const [loading, setLoading] = useState(true);
  const [isQRError, setIsQRError] = useState(false);

  let storeName = new URLSearchParams(params).get("outlet");
  let tableNo = new URLSearchParams(params).get("table_no");
  const qrCode = new URLSearchParams(params).get("QRCode");

  // temp support
  const storeNameTemp = new URLSearchParams(params).get("storename");
  if (storeNameTemp && storeNameTemp.length > 0) {
    storeName = storeNameTemp;
  }
  const tableNoTemp = new URLSearchParams(params).get("tableno");
  if (tableNoTemp && tableNoTemp.length > 0) {
    tableNo = tableNoTemp;
  }

  // clear dining mode after each scan
  localStorage.setItem(STORAGEKEY.DINING_MODE, "");

  localStorage.setItem(
    STORAGEKEY.STORE_NAME,
    storeName && storeName.length > 0 ? storeName : "BUGIS JUNCTION"
  );
  localStorage.setItem(STORAGEKEY.QR_CODE, qrCode);
  localStorage.setItem(STORAGEKEY.TABLE_NO, tableNo);

  // ? condition redirect after splash time over
  const QRCodeValidationFunction = (qrCode, tableNo) => {
    QRCodeValidation(qrCode, tableNo)
      .then((res) => {
        const result = res?.data?.data[0];
        console.log("result", result);
        if (result.result === "FAIL") {
          setIsQRError(true);
          navigate("/qr-not-valid");
        } else {
          navigate("/home");
        }
      })
      .catch(() => {});
  };

  const sessionCheckFunction = () => {
    userSessionId()
      .then((res) => {
        const sessionId =
          res &&
          res.data &&
          res.data.data[0] &&
          res.data.data[0].output[0].session_id;
        localStorage.setItem(STORAGEKEY.SESSION_ID, sessionId);

        if (
          // qrCode &&
          // qrCode.length > 0 &&
          // process.env.REACT_APP_MODE !== "pavilion"
          qrCode &&
          qrCode.length > 0
        ) {
          QRCodeValidationFunction(qrCode, tableNo);
        } else if (
          // (!qrCode || qrCode.length <= 0) &&
          // process.env.REACT_APP_MODE === "pavilion"
          !qrCode ||
          qrCode.length <= 0
        ) {
          navigate("/home");
        } else {
          navigate("/qr-not-valid");
        }
      })
      .catch((_err) => {});
  };

  useEffect(() => {
    // if (process.env.REACT_APP_MODE !== "gochiso") {
    //   setTimeout(() => {
    //     sessionCheckFunction();
    //     // if (
    //     //   localStorage.getItem(STORAGEKEY.STORE_NAME) &&
    //     //   localStorage.getItem(STORAGEKEY.TABLE_NO) !== "null" &&
    //     //   localStorage.getItem(STORAGEKEY.TABLE_NO) !== null &&
    //     //   localStorage.getItem(STORAGEKEY.QR_CODE) !== "null" &&
    //     //   localStorage.getItem(STORAGEKEY.QR_CODE) !== null &&
    //     //   localStorage.getItem(STORAGEKEY.SESSION_ID)
    //     // ) {
    //     //   setLoading(false);
    //     //   console.log("---------------", isQRError);
    //     //   if (!isQRError) {
    //     //     // navigate("/home");
    //     //   }
    //     // } else {
    //     //   navigate("/qr-not-valid");
    //     // }
    //     // if (isQRError) {
    //     //   navigate("/qr-not-valid");
    //     // }
    //   }, 3500);
    // }

    // set the session id
     userSessionId()
      .then((res) => {
        const sessionId =
          res &&
          res.data &&
          res.data.data[0] &&
          res.data.data[0].output[0].session_id;
        localStorage.setItem(STORAGEKEY.SESSION_ID, sessionId);
      })
      .catch((_err) => {});
  }, []);

  const formik = useFormik({
    initialValues: { terms_services: false, promotional: true },
    // validationSchema: Yup.object({
    //   terms_services: Yup.boolean().oneOf(
    //     [true],
    //     "The terms of service must be accepted"
    //   ),
    //   // promotional: Yup.boolean().oneOf(
    //   //   [true],
    //   //   "I do not wish to receive any marketing or promotional materials must be accepted"
    //   // ),
    // }),
    onSubmit: (values) => {
      sessionCheckFunction();
      navigate("/home");
    },
  });
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="title-bg position-absolute w-100 start-0 z-index-1">
            {/* {process.env.REACT_APP_MODE === "pavilion" && (
              <label className="position-absolute start-0 end-0 title-margin font-28 fw-bold line-height-45 hotel-title">
                The Authentic <br />
                South Indian Restaurant
              </label>
            )} */}
            <img
              src={`/assets/${process.env.REACT_APP_MODE}/logo/logo.png`}
              alt=""
              className="splash-logo-img mt-3 pt-2"
            />
          </div>
          <div className="h-50 position-absolute w-100 d-flex flex-column justify-content-end pb-5">
            <div className="terms-service">
              <Form className="" onSubmit={formik.handleSubmit}>
                <button
                  type="submit"
                  disabled={!formik.isValid}
                  className="continue-as-guest d-flex justify-content-center text-white align-items-center mb-2 mt-4 me-3 ms-3"
                >
                  Continue as Guest
                </button>
                <div className="pt-2 pb-2 ms-3 me-4 d-flex ps-4">
                    <span className="splash-text text-start text-black">
                      By clicking "Continue as Guest" I agree to Solution
                      Details'{" "}
                      <strong>
                        <Link to="/terms-of-use" className="text-black">
                          Terms of Use
                        </Link>
                      </strong>{" "}
                      and{" "}
                      <strong>
                        <Link to="/privacy-policy" className="text-black">
                          Privacy Policy.
                        </Link>
                      </strong>
                    </span>
                  </div>
                <Form.Group>
                  <div className="pb-2 d-flex ms-3 me-4">
                    <Form.Check
                      type="checkbox"
                      id="promotional"
                      name="promotional"
                      value={`promotional`}
                      label=""
                      onChange={formik.handleChange}
                    />
                    <span className="splash-text text-start text-black">
                      I do not wish to receive any marketing or promotional
                      materials.
                    </span>
                  </div>
                </Form.Group>
              </Form>
            </div>
            <div className="powered-by-evolut position-fixed d-flex justify-content-center bottom-0 align-items-center">
              <span>
                Powered by Solution Details - Evolut POS & Intelli Table QR
              </span>
            </div>
          </div>
          <img
            src={`/assets/${process.env.REACT_APP_MODE}/background/splash.png`}
            alt=""
            className="w-100 img-splash"
          />
        </div>
      </div>
    </>
  );
}

export default SplashScreen;
